@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    overflow-y: auto !important;
    padding: 0 !important;
  }
  html,
  body {
    @apply antialiased text-grey;
  }
  p {
    @apply mb-5;
  }
  p:last-of-type {
    @apply mb-0;
  }
  p > a {
    @apply transition text-grey;
  }
  p > a:hover {
    @apply text-grey;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  /* Header Menu */
  .headerMenu .menuItem > a::before {
    @apply absolute w-0 right-0 bg-grey -bottom-7;
    content: "";
    height: 3px;
    transition: all 0.3s ease-in-out 0s;
  }

  .loyaltyTableRow {
    @apply relative xl:static;

    &:nth-child(2) > .loyaltyTableCell {
      &:first-child > div > div {
        @apply bg-no-repeat md:bg-[url('/assets/images/cell_bronze.svg')] bg-[url('/assets/images/cell_bronze_m.svg')]  bg-cover;
      }
    }

    &:nth-child(3) > .loyaltyTableCell {
      &:first-child > div > div {
        @apply bg-no-repeat md:bg-[url('/assets/images/cell_silver.svg')] bg-[url('/assets/images/cell_silver_m.svg')] bg-cover;
      }
    }

    &:nth-child(4) > .loyaltyTableCell {
      &:first-child > div > div {
        @apply bg-no-repeat md:bg-[url('/assets/images/cell_gold.svg')] bg-[url('/assets/images/cell_gold_m.svg')] bg-cover;
      }
    }
    &:nth-child(5) > .loyaltyTableCell {
      &:first-child > div {
        @apply pb-0;
      }
      &:first-child > div > div {
        @apply bg-no-repeat md:bg-[url('/assets/images/cell_platinum.svg')] bg-[url('/assets/images/cell_platinum_m.svg')] bg-cover;
      }
    }
  }

  .loyaltyTableHeader {
    p {
      margin: 0;
      padding: 0;
    }

    & > div {
      @apply h-[88px] w-[160px] gap-2;
    }

    &:first-child > div {
      @apply w-[190px] md:w-[240px] gap-3;

      & > p:first-child {
        @apply sm:text-xl md:text-2xl leading-4;
      }

      & > p:nth-child(2) {
        @apply text-sm leading-3;
      }
    }

    &:first-child > div {
      box-shadow: rgba(0,0,0,0.3) 8px 3px 4px 0;
    }

    & > div > p:first-child {
      @apply text-lg font-semibold text-center leading-4;
    }

    & > div > p:nth-child(2) {
      @apply text-sm font-normal leading-3;
    }

    @apply h-[72px] sm:h-[88px] p-0 text-center relative first:left-0 first:sticky first:z-[5] first:top-0 bg-grey-20 lg:first:relative;
  }

  .loyaltyTableCell {
    &:first-child > div > div {
      @apply w-[190px] gap-y-1 md:gap-y-0 md:w-[240px] items-end grid grid-cols-[40px_1fr] md:grid-cols-[48px_1fr] grid-rows-2;
    }

    &:first-child > div > div > p {
      @apply text-sm md:text-[16px] font-normal justify-self-start self-start;
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }

    &:first-child > div {
      @apply text-white w-[190px] md:w-[240px];
      box-shadow: rgba(0,0,0,0.3) 8px 2px 4px 0;
    }

    & > div {
      @apply h-[72px] sm:h-[84px] pt-2 w-[160px];
    }

    & > div > div {
      @apply bg-grey-20 w-[160px] h-[64px] sm:h-[72px] text-center;
    }

    sub {
      @apply text-xs font-normal;
    }

    sup {
      @apply text-xs font-normal;
    }

    & > div > div > p {
      @apply text-2xl font-normal m-0 leading-3;
    }

    @apply relative p-0 xl:first:relative first:sticky first:left-0 first:z-[5];
  }

  .megaMenu,
  .subMenu {
    transition: all 0.4s;
    top: calc(100% + 25px);
  }
  .megaMenu {
    width: 970px;
    @apply opacity-0;
  }
  @media (min-width: 1280px) {
    .megaMenu {
      width: 1070px;
    }
  }
  @media (min-width: 1500px) {
    .megaMenu {
      width: 1200px;
    }
  }
  .megaMenu ul > li:last-of-type {
    @apply pb-0 mb-0 border-0;
  }
  .subMenu {
    width: 220px;
  }
  @media (min-width: 1280px) {
    .subMenu {
      width: 240px;
    }
  }
  .subMenuChild {
    transition: all 0.3s;
  }
  .subMenu li:hover > .subMenuChild {
    @apply visible opacity-100 -top-3;
  }
  .subMenu > ul > li:hover {
    @apply hover:text-grey hover:bg-grey-30;
  }
  .megaMenu .subMenuGrid > li:last-of-type {
    @apply pb-0 mb-0 border-b-0;
  }

  .headerMenu .menuItem:hover > a::before {
    @apply w-full right-auto left-0;
  }
  .headerMenu .menuItem > .megaMenu {
    @apply invisible;
  }

  /* Category Menu & Header Three */
  .categoryMegaMenu {
    @apply invisible opacity-0 top-6;
    transition: all 0.4s;
  }
  .categoryMegaMenu .megaMenu {
    @apply relative top-0 opacity-100 w-[630px] 2xl:w-[720px] !left-0;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.08);
  }
  .categoryMegaMenu .megaMenu > .grid {
    @apply grid-cols-3;
  }
  .categoryMegaMenu .megaMenu > .grid > ul {
    @apply bg-white odd:bg-grey-20;
  }
  .subMenu li:hover > .categoryMegaMenu {
    @apply top-0 visible opacity-100;
  }
  .categoryMenu > .subMenu > ul {
    @apply py-6;
  }
  .categoryMenu > .subMenu > ul > li > a {
    @apply py-2.5;
  }
  .categoryMenu:hover > .subMenu {
    @apply visible opacity-100;
    top: calc(100% + 10px) !important;
  }
  .headerThree .headerMenu .menuItem > .megaMenu {
    @apply left-0 xl:left-0;
  }
  .headerThree .headerMenu > .menuItem {
    @apply py-3;
  }
  .headerThree .headerMenu .menuItem > a::before {
    @apply -bottom-3;
  }

  /* Hide the browser built-in summary/details markers */
  details { user-select: none; }
  details > summary { list-style: none; }
  details > summary::marker, /* Latest Chrome, Edge, Firefox */
  details > summary::-webkit-details-marker /* Safari */ {
    display: none;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #212121;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #707070;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: #707070;
  }
  input::placeholder {
    color: #707070;
  }

  .modal-root .os-content {
    @apply flex items-center justify-center lg:block;
  }

  .carouselWrapper .product-gallery + div {
    @apply hidden;
  }
  .carouselWrapper.pagination-left .swiper-pagination-bullets {
    @apply left-6 sm:left-9 md:left-11 lg:left-14 xl:left-20 2xl:left-24 3xl:left-28;
  }
  .carouselWrapper.carousel-full-width.pagination-left .swiper-pagination-bullets {
    @apply left-5 sm:left-9 md:left-11 lg:left-14 xl:left-20 2xl:left-24 3xl:left-48;
  }
  .carouselWrapper.product-gallery .swiper .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply w-8 bg-grey-90;
  }
  .carouselWrapper.product-gallery .swiper .swiper-pagination-bullets .swiper-pagination-bullet {
    @apply h-4 w-4 !mx-[6px] md:!mx-2;
  }

  /* styles for cashback slider input */
  input[type='range']::-webkit-slider-thumb {
    border-style: double;
    -webkit-appearance: none;
    appearance: none;
    width: 35px;
    height: 35px;
    background: #374149;
    border-radius: 50%;
    border: 3px solid white;
    cursor: pointer;
    outline: 2px solid #374149;
  }
}

